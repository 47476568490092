<template>
    <div class="personalinfoContainerx1">
        
        <div class="personMessage" @click="personClick"> 
            <!-- <div class="messageTitle"> 系统后台消息</div> -->
        </div>
        <!-- <div class="studentMessage" @click="studentClick"></div> -->

        <div style="clear:both"></div>

        <div v-if="!showMessage" class="messageList">
            <div class="inputStyle">
                <el-select type="text" class="inputSelect"  v-model="messageType">

                    <el-option value="学术问题" label="学术问题"></el-option>
                    <el-option value="流程问题" label="流程问题"></el-option>
                    <el-option value="系统操作问题" label="系统操作问题"></el-option>
                    <el-option value="数据问题" label="数据问题"></el-option>
                    <el-option value="奖项证书问题" label="奖项证书问题"></el-option>
                    <el-option value="其他问题" label="其他问题"></el-option>

                </el-select>
                <el-input v-model="messageStr" class="inputStyle1" ></el-input>
                <el-button  class="sendStyle" @click="sendMessage">提交</el-button>
            </div>


            <div v-for="(x,index) in messageList" :key="index" class="messageDetail"> 
                <div style="color:#001D9F;font-size:15px">
                    {{x.type}}
                </div>
                 <div style="color:#00359F;font-size:13px;margin-top: 10px;">
                    {{x.message_content}}
                </div>

                <div v-if="x.reply_content !=''" style="color:#00359F;font-size:13px;margin-top: 10px">
                    {{'官方回复：' + x.reply_content}}
                </div>


                <div style=" background-color:#00359F;height:1px;margin-top: 10px">
                </div>
            </div>
        </div>

        <!-- <div v-if="showMessage" class="studentmessageList"></div> -->


    </div>
</template>

<script>
import {sendMessage,getMyMessages} from '../../api/index'
import {getUserId,getUserType,getProjectCode} from '../../utils/store'
import '../../assets/common/font.css'

export default {
    data(){
        return{
            user:{},
            user_type:getUserType(),
            imageUrl:'',
            messageList:[],
            messageStr:'',
            showMessage:false,
            messageType:'请选择消息类型',


            timer: '',

        }
    },
    mounted(){
        this.getMyMessages()

        // this.timer = setInterval(this.showRedMessage, 5000);

    },
    beforeDestroy() {
      clearInterval(this.timer);
    },
    methods:{

        showRedMessage() {


            getMyMessages(getUserId(),getProjectCode()).then(res=>{
                // console.log(1111,res)

                this.traversalData(res.data.data);

            })
            console.log(11)
        },
        traversalData(newData){

            var haveNoReply = false
            var newTip = false

            for (var i = 0; i < newData.length; i++) {
                var message = newData[i];
                if (message.message_reply_id == ''){
                    haveNoReply = true
                }

                if (i < this.messageList.length){

                    var oldmessage = this.messageList[i];

                    if (message.message_reply_id == oldmessage.message_reply_id){
                        console.log('是一致的')
                    }else{
                        console.log('是不一致的')
                        newTip = true
                    }

                }

            }

            
            console.log('是否需要刷新',haveNoReply)
            console.log('是否需要提示新消息',newTip)

            if (haveNoReply == false){
                clearInterval(this.timer);
            }

            this.messageList = newData


        },
        clearTime(){
            clearInterval(this.timer);
        },


        getMyMessages(){

            getMyMessages(getUserId(),getProjectCode()).then(res=>{
                // console.log(1111,res)
                this.messageList = res.data.data
            })
        },
       
        updateUserAvatar(url){


            updateUserAvatar(getUserId(),url).then(res=>{
                this.getUser()
            })
        },

        sendMessage(){


            // this.timer = setInterval(this.showRedMessage, 5000);


            if (this.messageType == '请选择消息类型'){
                this.$message.error('请选择消息类型');
                return
            }
            if (this.messageStr == ''){
                this.$message.error('请输入内容');
                return
            }

            sendMessage(getUserId(),this.messageType,'student',this.messageStr,getProjectCode()).then(res=>{
                this.messageStr = ''
                this.messageType = '请选择消息类型'

                this.$message({
                message: '发送成功',
                type: 'success'
                });

                this.getMyMessages()
            })

        },
        personClick(){
           this.showMessage = false;
        },
        studentClick(){
           this.showMessage = true;
        },
        handleEventName(event){
            if(event){
                let event_array = event.split(" ")
                return event_array[0]
            }else{
                return ''
            }
        }
    }
}
</script>

<style lang="less">
.personalinfoContainerx1{
    width: 90%;
    height: 90%;
    margin-left: 40px;
    margin-top: 50px;
    // background-color: red;
    .window{
        margin-top: 1vh;
        position: relative;
        .windowImg{
            width: 100%;
            height: 60vh;
            left: 0;
            top:0;
            z-index: -1;
            position: absolute;
        }
        .windowContent{
            width: 100%;
            height: 100%;
            overflow: hidden;
           
           
        }
    }
     .itemLine{
        // position: absolute;
        display: flex;
        justify-content: space-between;
        width: 80%;
        margin: 5vh auto;
        .item{
            width: 45%;
            padding-bottom: 10px;
            border-bottom: 2px solid #072D76;
            color: #072D76;
            font-size: 2.2vh;
            font-family: Montserrat-SemiBold;
            font-weight: bold;
        }
    }
    .personMessage{
        // display: inline;
        float: left;
        width: 200px;
        height: 70px;
        border-radius: 10px;
        background-color: blue;
        // margin-left: 30px;
        margin-top: 20px;
        background-image: url("../../assets/adminMessage.png");
        background-repeat: no-repeat;
        background-color: transparent;
        // background-position: 100% 100%;
        background-size: 200px 70px;
        
    }
    .messageTitle{
        color: white;
        margin-left: 120px;
        margin-top: 30px;
        font-family: Montserrat-SemiBold;
    }
    .studentMessage{
        float: left;;
        width: 40%;
        height: 60px;
        border-radius: 10px;
        background-color: blue;
        margin-left: 30px;
        margin-top: 20px;
        font-family: Montserrat-SemiBold;
    }
    .inputStyle{
        width: 100%;
        height: 60px;
    }
    .inputSelect{
        margin-left: 20px;
        width: 150px;
        height: 40px;
    }
    .inputStyle1{
        width: 50%;
        height: 40px;
        margin-left: 20px;
        margin-top: 10px;
    }
    .sendStyle{
        margin-left: 10px;
        background-color: #00359F;
        color: white;
        font-family: Montserrat-SemiBold;
    }
    .messageList{

        width: calc(80% + 30px);
        height: calc(90% - 90px);
        background-color: white;
        margin-top: 30px;
        overflow-x: hidden;
        overflow-y: scroll;
        border-radius: 10px;
    }
    .list{
        overflow-x: hidden;
        overflow-y: scroll;
    }
    .messageDetail{
        margin-left: 20px;
        margin-top: 10px;
        width: calc(100% - 40px);
        font-family: Montserrat-SemiBold;
        // height: 50px;

    }
    .studentmessageList{

        width: calc(80% + 30px);
        height: calc(90% - 90px);
        background-color: #072D76;
        margin-top: 30px;
    }


    .uploadIcon{
        position: absolute;
        // width: 80px;
        // height: 20px;
        border-radius: 7.5px;
        background-color: #072D76;
        margin-left: 30px;
        margin-top: 120px;
        align-items: center;
    }
    .uploadIconBtn{
        // position: absolute;
        border: none;
        width: 80px;
        height: 15px;
        border-radius: 7.5px;
        background-color: #072D76;
        margin-left: 0px;
        margin-top: 0px;
        // margin: 0px;
        text-align: center;
        color: white;
        font-family: Montserrat-SemiBold;
    }
    .nameItem{
        position: absolute;

        margin-left: 140px;
        margin-top: 30px;
        color: #072D76;
        font-size: 20px;
        font-family: Montserrat-SemiBold;

    }
    .accountItem{
        position: absolute;

        margin-left: 140px;
        margin-top: 60px;
        color: #072D76;
        font-size: 18px;
        font-family: Montserrat-SemiBold;
    }

    .emailItem{
        position: absolute;

        margin-left: 140px;
        margin-top: 90px;
        color: #072D76;
        font-size: 18px;
        font-family: Montserrat-SemiBold;
    }
    .lineItem{
        position: absolute;

        margin-left: 140px;
        margin-top: 130px;
        width: 300px;
        height: 1px;
        background-color: #072D76;
        font-size: 18px;
    }
    .eventItem{
        position: absolute;
        margin-left: 140px;
        margin-top: 160px;
        color: #072D76;
        font-size: 18px;
        font-family: Montserrat-SemiBold;
    }
    .eventInfo{
        margin-top: 10px;
        margin-left: 0px;
        color: #072D76;
        font-size: 13px;
        font-family: Montserrat-SemiBold;
    }
    
}


</style>